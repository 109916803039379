<template>
  <v-container>
      <h1 class="textCenter mb-10">
        {{$t("nav.politeness-phrases")}}
    </h1>
    <v-card v-for="(phrase, index) in phrases" :key="index" class="mb-3">
      <v-card-title class="mb-0 pb-0">
        <p>
          <v-icon color="error" class="mr-4">warning</v-icon> {{$t("global.incorrect")}}:
          <b> {{ $t(`politenessPhrases.incorrect.${phrase.id}`) }} <i v-if="phrase.description" class="grey--text text-darken-1"> ({{$t(`politenessPhrases.description.${phrase.id}`)}})</i></b>
        </p>
      </v-card-title>
      <v-card-title>
        <p>
          <v-icon color="green" class="mr-4">check</v-icon>  {{$t("global.correct")}}:
          <b> {{ $t(`politenessPhrases.correct.${phrase.id}`) }}</b>
        </p>
      </v-card-title>
    </v-card>
  </v-container>
</template>

<script>
import { politenessPhrases } from "@/helpers/constants";
export default {
  data() {
    return {
      phrases: politenessPhrases,
    };
  },
};
</script>
<style>
</style>